<template>
    <div class="home-about">
        <div class="my-container">
            <div class="home-about_top">
                <div>
                    <h2 class="header">{{ t('aboutCompany') }}</h2>
                    <p>{{ about.description[locale] }}</p>
                </div>
                <NuxtLink :to="localePath('/about')" class="my-btn pc">{{ t('detailed') }}</NuxtLink>
            </div>
            <div class="home-about_bottom">
                <div class="left">
                    <img :src="about.media_file" v-if="about.media_file_type === 'image'" />
                    <video controls="" v-else>
                        <source type="video/mp4" :src="about.media_file">
                    </video>
                </div>
                <div class="about-card" v-for="(item, index) in about.items" key="index">
                    <div>{{ item.title[locale] }}</div>
                    <p>{{ item.description[locale] }}</p>
                </div>
                <NuxtLink :to="localePath('/about')" class="my-btn mobile">{{ t('detailed') }}</NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
const { locale, t } = useI18n()
const props = defineProps(['about'])
const localePath = useLocalePath()
</script>